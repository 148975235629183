import React, { useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Text } from "../Text";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useCanvasImageSequence } from "./useCanvasImageSequence";
import { StaticImage } from "gatsby-plugin-image";
import { pxToRem } from "../../utils/px-to-rem";
import { enterTextViaScrambling } from "../../utils/text-animations";
import { SplitText } from "gsap/SplitText";
import TransitionLink from "gatsby-plugin-transition-link";
import { rgba } from "polished";
import { TransitionPortal } from "gatsby-plugin-transition-link";
import { withPrefix } from "gatsby";

import Portal from "../Portal";
import {
    DrLogo,
    ScrollButtonArrow,
    ScrollButtonCircle,
    ScrollButtonCircleWhite
} from "../../assets/misc-svgs/components";

const URLS = [
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/000.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/001.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/002.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303352/gj/output-gj/003.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/004.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/005.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303357/gj/output-gj/006.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/007.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/008.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/009.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303357/gj/output-gj/010.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/011.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/012.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/013.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/014.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303352/gj/output-gj/015.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/016.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/017.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/018.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/019.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/020.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/021.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/022.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/023.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/024.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/025.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303357/gj/output-gj/026.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/027.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/028.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/029.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/030.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303359/gj/output-gj/031.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/032.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/033.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/034.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/035.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/036.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/037.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/038.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/039.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/040.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/041.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303352/gj/output-gj/042.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/043.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/044.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/045.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303357/gj/output-gj/046.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303357/gj/output-gj/047.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/048.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/049.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/050.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303357/gj/output-gj/051.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/052.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/053.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303352/gj/output-gj/054.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/055.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/056.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/057.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/058.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/059.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/060.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/061.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/062.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/063.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/064.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/065.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303359/gj/output-gj/066.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/067.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303352/gj/output-gj/068.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/069.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/070.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/071.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/072.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/073.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/074.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/075.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/076.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/077.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/078.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/079.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/080.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/081.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/082.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/083.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303357/gj/output-gj/084.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/085.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303352/gj/output-gj/086.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303352/gj/output-gj/087.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/088.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/089.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303352/gj/output-gj/090.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303352/gj/output-gj/091.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303357/gj/output-gj/092.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/093.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/094.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/095.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/096.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303352/gj/output-gj/097.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/098.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/099.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/100.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/101.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303357/gj/output-gj/102.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/103.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/104.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/105.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/106.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303352/gj/output-gj/107.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/108.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/109.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/110.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303352/gj/output-gj/111.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/112.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/113.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303357/gj/output-gj/114.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/115.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/116.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/117.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303357/gj/output-gj/118.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/119.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/120.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/121.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/122.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/123.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/124.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/125.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/126.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/127.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/128.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/129.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/130.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303351/gj/output-gj/131.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/132.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/133.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/134.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/135.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/136.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303355/gj/output-gj/137.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303357/gj/output-gj/138.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/139.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/140.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303350/gj/output-gj/141.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303358/gj/output-gj/142.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303357/gj/output-gj/143.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303356/gj/output-gj/144.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303353/gj/output-gj/145.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303352/gj/output-gj/146.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303349/gj/output-gj/147.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/148.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303354/gj/output-gj/149.webp",
    "https://res.cloudinary.com/candyman/image/upload/v1682303352/gj/output-gj/150.webp"
];

interface ICanvasImageSequence {
    imageWidth: number;
    imageHeight: number;
    enabled?: boolean;
}

const drawFrame = (
    n: number,
    context: CanvasRenderingContext2D,
    frames: any[],
    canvas: HTMLCanvasElement
) => {
    const currentFrame = Math.round(n);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(frames[currentFrame], 0, 0, canvas.width, canvas.height);
};

export const WelcomeSection = ({
    imageWidth,
    imageHeight
}: ICanvasImageSequence) => {
    const mainRef = useRef(null);
    const tl = useRef<GSAPTimeline>(null);
    const [enabled, setEnabled] = useState(false);

    const splitChars = useRef(null);
    const { loaded, canvasScale, frames, scaledBy } = useCanvasImageSequence({
        imageUrls: URLS,
        imageHeight,
        imageWidth,
        mainRef
    });

    useLayoutEffect(() => {
        gsap.registerPlugin(SplitText);
        //run once

        const ctx = gsap.context(() => {
            splitChars.current = new SplitText(".welcome-text", {
                type: "chars",
                charsClass: "char",
                linesClass: "line",
                wordsClass: "word"
            });
            gsap
                .timeline()
                .set(".welcome-text", { autoAlpha: 1 })
                .fromTo(
                    splitChars.current.chars,
                    {
                        autoAlpha: 0
                    },
                    {
                        autoAlpha: 1,
                        stagger: {
                            each: 1 / splitChars.current.chars.length
                        },
                        ease: "power3.out"
                    }
                )
                .fromTo(
                    ".welcome-jv-image",
                    {
                        autoAlpha: 0
                    },
                    {
                        autoAlpha: 1,
                        duration: 3,
                        ease: "power3.out"
                    }
                )
                .add(
                    enterTextViaScrambling(".welcome-caption-text", 1).play(),
                    "<+=80%"
                )
                .fromTo(
                    ".gj-cut-image",
                    { yPercent: 100, autoAlpha: 0 },
                    {
                        yPercent: 0,
                        autoAlpha: 1,
                        duration: 2,
                        ease: "power3.out"
                    },
                    "<+=50%"
                )
                .to("#welcome-scroll-button", {
                    autoAlpha: 1
                })
                .then(() => {
                    setEnabled(true);
                });
        }, mainRef);

        return () => ctx.revert();
    }, []);

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        //run once

        const ctx = gsap.context(() => {
            const canvas =
                gsap.utils.toArray<HTMLCanvasElement>(".image-canvas")[0];
            // const repositionCanvas = () => {
            //     const box = canvas.getBoundingClientRect();
            //     console.log(box);
            //
            //     gsap.to(canvas, {
            //         // x: -left / 2,
            //         // y: -top,
            //         duration: 0
            //     });
            // };
            //
            // repositionCanvas();
            if (loaded) {
                // canvas.style.left = "0px";
                // canvas.style.top = "0";
                // canvas.style.position = "absolute";

                // window.addEventListener("resize", repositionCanvas, false);

                const frameNumberTweener = {
                    value: 1
                };

                const context = canvas.getContext("2d");

                // Hack: the first frame wasn't showing before the main scrolltrigger got activated, so doing this
                ScrollTrigger.create({
                    trigger: mainRef.current,
                    start: "top bottom",
                    onEnter: () => {
                        drawFrame(0, context, frames, canvas);
                    },
                    invalidateOnRefresh: true
                });

                tl.current = gsap
                    .timeline({
                        defaults: {
                            ease: "none"
                        },
                        scrollTrigger: {
                            trigger: ".main-container",
                            end: `${window.innerHeight * 10}`,
                            scrub: 0.75,
                            pin: true,
                            invalidateOnRefresh: true
                            // pin: true,
                        }
                    })
                    .to(".welcome-center-container", {
                        autoAlpha: 0
                    })
                    // .set(".gj-cut-image", {
                    //     autoAlpha: 1
                    // })
                    .to(
                        ".gj-cut-image",
                        {
                            y: () => {
                                const el =
                                    gsap.utils.toArray<HTMLElement>(
                                        ".gj-cut-image"
                                    )[0];
                                const box = el.getBoundingClientRect();

                                return (
                                    window.innerHeight / 2 -
                                    box.top -
                                    box.height / 2
                                );
                            },
                            rotate: "0deg"
                        },
                        "<+=60%"
                    )
                    .to(mainRef.current, {
                        duration: 0.25
                    })
                    .to(".gj-cut-image", {
                        yPercent: -150,
                        rotate: "-45deg"
                    })
                    .to(
                        [
                            ".welcome-container",
                            [
                                document.querySelector(".kaju-katli-text"),
                                document.querySelector(".dessert-roz-logo")
                            ]
                        ],
                        {
                            autoAlpha: 0,
                            duration: 0.5
                        },
                        "<+=70%"
                    )
                    .to(
                        "#chaashni-background",
                        {
                            visibility: "hidden",
                            autoAlpha: 0
                        },
                        "<"
                    )
                    .to(
                        canvas,
                        {
                            autoAlpha: 1
                        },
                        "<"
                    )
                    .to(frameNumberTweener, {
                        duration: 8,
                        value: URLS.length - 1,
                        onUpdate: () => {
                            drawFrame(
                                frameNumberTweener.value,
                                context,
                                frames,
                                canvas
                            );
                        }
                    })
                    .to(
                        "#welcome-scroll-button",
                        {
                            autoAlpha: 0
                        },
                        "<"
                    )
                    // .to(mainRef.current, {
                    //     duration: 1
                    // })
                    .to(
                        ".main-container",
                        {
                            scale: 20,
                            duration: 5,
                            xPercent: 38,
                            yPercent: 40,
                            rotate: "290deg"
                        },
                        "<+=99%"
                    )
                    .set(
                        "#scroll-button-initial-arrow",
                        {
                            display: "none"
                        },
                        "<"
                    )
                    .to(
                        document.querySelector("#welcome-overlay"),
                        {
                            autoAlpha: 1,
                            duration: 0.5,
                            onComplete: () => {
                                const btn =
                                    gsap.utils.toArray<HTMLAnchorElement>(
                                        ".to-jv-link"
                                    )[0];
                                btn && btn.click();
                            }
                        },
                        "<+=50%"
                    );

                    // tl.current.scrollTrigger.disable();
                    // if(enabled) {
                    //     tl.current.scrollTrigger.enable();
                    // }
                // .add(function () {
                //
                // });
                // .add(() => {
                //     // navigate("/jamunverse");
                // }, "<+=70%");
            }
        }, mainRef);

        return () => ctx.revert();
    }, [loaded]);

    useLayoutEffect(() => {
        if (enabled && loaded) {
            gsap.set(document.body, {
                overflowY: "scroll"
            });
        } else {
            gsap.to(document.body, {
                clearProps: "overflow"
            }).then(() => {
                gsap.set(document.body, {
                    overflowY: "hidden"
                });
            });
        }
    }, [enabled, loaded]);

    const EXIT_ANIM_DURATION = 2.5;

    const exitAnimation = () => {
        const ctx = gsap.context(() => {
            gsap.timeline({
                defaults: {
                    duration: EXIT_ANIM_DURATION
                }
            })
                .set(".main-container", {
                    autoAlpha: 0,
                    ease: "power4.inOut"
                })
                .to(document.querySelector("#welcome-overlay"), {
                    autoAlpha: 0,
                    duration: EXIT_ANIM_DURATION,
                    ease: "power4.out"
                })
                .to(
                    document.querySelector("#loading-text-pre"),
                    {
                        autoAlpha: 1
                    },
                    "<+=20%"
                )
                .then(() => {
                    console.log("exit complete");
                });
        }, mainRef);
    };

    return (
        <div ref={mainRef} className="welcome-section-container" css={{}}>
            <TransitionLink
                className="to-jv-link"
                exit={{
                    trigger: ({ exit, node }) => {
                        console.log("exiting");
                        exitAnimation();
                    },
                    length: EXIT_ANIM_DURATION
                }}
                entry={{
                    trigger: ({ entry, node }) => {
                        console.log("entered");
                    },
                    delay: EXIT_ANIM_DURATION * 1.21
                }}
                to="/jamunverse"
            >
                {/*<Link to="/fun-facts/fact-1-puzzle">*/}
                <div
                    css={{
                        display: "none"
                    }}
                >
                    <button />
                </div>
            </TransitionLink>
            <div
                id="welcome-scroll-button"
                className="scroll-button"
                css={{
                    opacity: 0,
                    position: "fixed",
                    right: pxToRem(24),
                    bottom: pxToRem(24),
                    width: pxToRem(80),
                    height: pxToRem(80)
                }}
            >
                <div css={{ position: "relative", height: "100%" }}>
                    <div
                        css={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0
                        }}
                    >
                        <img
                            id="scroll-button-initial-arrow"
                            css={{
                                width: "20%",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                zIndex: 5,
                                transform: "translate(-50%, -50%)",
                                // right: "-20%",
                                filter: `drop-shadow(0px 0px ${pxToRem(
                                    4
                                )} #FF9B54)`,
                                padding: "10px",
                                overflow: "visible"
                                // filter: `drop-shadow(0px 0px 3px #FF8B38)`
                            }}
                            src="/images/drag-arrow.png"
                            alt=""
                        />

                        {/* <ScrollButtonCircle
                            className="bg bg-orange"
                            css={{
                                width: "100%",
                                filter: `drop-shadow(0px 0px ${pxToRem(
                                    1
                                )} #FF9B54)`,
                                overflow: "visible",

                                // path: {
                                //     strokeWidth: px
                                path: {
                                    // strokeWidth: pxToRem(2)
                                }
                                // }
                            }}
                        /> */}
                    </div>
                </div>
            </div>

            <div
                className="main-container"
                css={{
                    transform: "translate(0, 0)",
                    position: "fixed",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100% !important",
                    height: "100% !important",
                    overflow: "hidden"
                    // transform: "translateZ(0)"
                }}
            >
                <div
                    // className="main-container"
                    css={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100% !important",
                        height: "100% !important",
                        overflow: "hidden"
                    }}
                >
                    <div
                        className="welcome-container"
                        css={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                            zIndex: 2,
                            willChange: "transform",
                            // background: "#1E1E1E",
                            display: "grid",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden"
                        }}
                    >
                        <div
                            className="gj-cut-image"
                            css={{
                                opacity: 0,
                                width: "30vw",
                                position: "absolute",
                                bottom: "-18vw",
                                left: "50%",
                                transform: "translateX(-50%) rotate(120deg)"
                            }}
                        >
                            <StaticImage
                                quality={50}
                                src="../../images/home-gj-cut.png"
                                alt=""
                                placeholder="none"
                                css={{
                                    filter: `drop-shadow(0 0 2.75rem ${rgba(
                                        "#FF8B38",
                                        0.5
                                    )})`,
                                    background: "transparent"
                                }}
                            />
                        </div>
                        <div
                            className="welcome-center-container"
                            css={{
                                willChange: "transform",
                                width: "40vw",
                                marginTop: "-10vh",
                                display: "grid",
                                alignItems: "center",
                                justifyContent: "center",
                                gridGap: pxToRem(48)
                            }}
                        >
                            <div>
                                <Text
                                    className="welcome-text"
                                    variant="welcomeToText"
                                    color="#A1A1A8"
                                    css={{
                                        opacity: 0,
                                        display: "grid",
                                        alignItems: "center",
                                        justifyContent: "stretch",
                                        gridAutoFlow: "column",
                                        ".char": {
                                            textAlign: "center",
                                            "&:nth-child(8)": {
                                                marginLeft: pxToRem(40)
                                            }
                                        }
                                    }}
                                >
                                    Welcome to
                                </Text>
                            </div>

                            <StaticImage
                                className="welcome-jv-image"
                                src="../../images/jamunverse-text.png"
                                alt=""
                                css={{
                                    opacity: 0
                                }}
                            />

                            <Text
                                className="welcome-caption-text"
                                variant="caption"
                                color="#A1A1A8"
                                css={{
                                    opacity: 0,
                                    textAlign: "center"
                                }}
                            >
                                released to your nearest sweet shop
                            </Text>
                        </div>
                    </div>

                    <div
                        css={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            left: "0px",
                            bottom: "0px",
                            willChange: "transform",
                            transform: `scale(${canvasScale})`,
                            zIndex: 200,
                            transformOrigin: "left top"
                        }}
                    >
                        <canvas
                            className="image-canvas"
                            css={{
                                // position: "absolute",
                                marginTop:
                                    scaledBy === "width"
                                        ? `calc((${imageHeight}px * ${canvasScale} - 100vh) / -4)`
                                        : "0px",
                                marginLeft:
                                    scaledBy === "height"
                                        ? `calc((${imageWidth}px * ${canvasScale} - 100vw) / -4)`
                                        : "0px",

                                // width: "100%",
                                // height: "100%",
                                // position: "absolute",
                                // top: "0px",
                                // right: "0px",
                                // left: "0px",
                                // bottom: "0px",
                                willChange: "transform",
                                opacity: 0
                                // transform: `scale(${canvasScale})`,
                                // transformOrigin: "center center"
                            }}
                            width={imageWidth}
                            height={imageHeight}
                        />
                    </div>
                    <Portal selector="#welcomepage-transition-portal">
                        <div
                            className="kaju-katli-text"
                            css={{
                                position: "fixed",
                                bottom: "3vh",
                                left: "2vw",
                                zIndex: 202
                            }}
                        >
                            <a href={withPrefix("/kaju-katli.html")}>
                                <Text
                                    className="gj-menu-item"
                                    color="white"
                                    variant="mainNav"
                                >
                                    Kaju Katli
                                </Text>
                            </a>
                        </div>
                        <div
                            className="dessert-roz-logo"
                            css={{
                                position: "fixed",
                                top: "3vh",
                                left: "50vw",
                                transform: "translateX(-50%)",
                                zIndex: 202,
                                svg: {
                                    width: pxToRem(120)
                                }
                            }}
                        >
                            <DrLogo />
                        </div>
                        <div
                            id="welcome-overlay"
                            className="overlay"
                            css={{
                                willChange: "transform",
                                position: "fixed",
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                                zIndex: 201,
                                opacity: 0,
                                background: "#FEFDC3"
                            }}
                        />
                        <div
                            id="loading-text-pre"
                            css={{
                                opacity: 0,
                                zIndex: 200,
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: "100vw",
                                height: "100vh",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <Text color="white" variant="title">
                                ...
                            </Text>
                        </div>
                    </Portal>
                </div>
            </div>
        </div>
    );
};

export default WelcomeSection;
